import React from "react"
import { Link } from "gatsby"
import NavigationBar from "../components/Navbar"
import LiveCourses from "../components/LiveCourseComponent"
import MachineLearningCourseImg from "../img/machine-learing-course.svg"
import MlInterviewImg from "../img/machine-learning.png"
import { mlCourseModuleList } from "../data/mlCourseData"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
const Footer2 = loadable(() => import("../components/footer3"))
const SocialMedia = loadable(() => import("../components/social-media"))
const Scroll = loadable(() => import("../components/scroll"))
const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const CoursesAndBlogs = loadable(() => import("../components/CoursesAndBlogs"))

const TableItem = ({ index, title, link }) => (
  <tr className="h-12 bg-white shadow-md border">
    <td className="px-2 py-2">
      <div className="flex items-center">
        <div className="flex w-6 h-6 text-sm sm:text-xs md:text-sm xl:text-base 2xl:text-lg items-center justify-center text-gray-900">
          {index}
        </div>
        <div className="ml-4">
          <div className="text-new-blue hover:underline text-sm sm:text-xs md:text-sm xl:text-base 2xl:text-lg">
            <Link to={link}>{title}</Link>
          </div>
        </div>
      </div>
    </td>
  </tr>
)

const ModuleTable = ({ moduleTitle, items }) => (
  <div className="bg-white">
    <h3 className="text-base sm:text-sm md:text-base text-center xl:text-lg 2xl:text-xl text-gray-900 tracking-wider">
      <span>{moduleTitle}</span>
    </h3>
    <table className="mt-4 w-full">
      <tbody>
        {items.map(({ chapterNumber, chapterName, path }) => (
          <TableItem
            key={chapterNumber}
            index={chapterNumber}
            title={chapterName}
            link={path}
          />
        ))}
      </tbody>
    </table>
  </div>
)

const MachineLearningCoursePage = () => {
  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Machine Learning Course</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          property="og:description"
          content="Project-based ML course by Enjoy Algorithms to master machine learning algorithms and prepare for tech interview. We have organized ML concepts and projects in a guided order."
        />
        <meta name="author" content="Ravish Raj" />
        <meta name="image" property="og:image" content={MlInterviewImg} />
        <meta
          name="keywords"
          content="ml interview preparation, enjoyalgorithms ml course, ml online course, ml course enjoy algorithms, machine learning, machine learning course, machine learning online course, ml course, ml self-paced course, best ml interview preparation course"
        />
        <meta
          name="title"
          property="og:title"
          content="Project Based Machine Learning Course"
        />
      </Helmet>

      <div className=" sm:sticky top-0 z-50">
        <NavigationBar />
      </div>

      <div className="bg-light-white">
        <div className="container mx-auto px-5 md:px-7 lg:px-8 xl:px-10">
          <div className="md:grid md:grid-cols-12">
            <div className="pt-4 md:pt-6 xl:pt-8 col-span-7 ">
              <h1 className="tracking-wider text-xl md:text-2xl xl:text-3xl text-gray-900 text-left sm:text-center md:text-left">
                Project Based ML Course
              </h1>
              <p className="tracking-wider mt-3 text-base sm:text-sm xl:text-base 2xl:text-lg text-gray-600 md:w-5/6">
                A free self-paced machine learning course for students and
                professionals who want to build machine learning projects in
                Python, master ML algorithms, and prepare for tech interviews.
              </p>
              <h2 className="sm:hidden lg:block mt-6 tracking-wider text-gray-900 text-lg md:text-xl xl:text-2xl">
                What will you learn?
              </h2>
              <div className="mt-6 sm:hidden grid gap-6 sm:grid-cols-2 lg:grid text-gray-600 tracking-wider text-base sm:text-sm xl:text-base 2xl:text-lg">
                <p>&#10003; Python and Math for ML</p>

                <p>&#10003; Machine Learning Concepts</p>
                <p>&#10003; ML Projects with Python Code </p>
                <p>&#10003; Guidance for ML Job Interview </p>
              </div>
            </div>

            <div className="mt-6 col-span-5 flex justify-center">
              <img
                className="object-cover pointer-events-none w-72 h-40 sm:w-112 sm:h-64 md:w-72 md:h-40 lg:w-96 lg:h-52 xl:w-112 xl:h-64 2xl:w-128 2xl:h-72"
                src={MachineLearningCourseImg}
                alt="enjoyalgorithms machine learning course"
              />
            </div>
          </div>

          <h2 className="tracking-wider text-gray-900 mt-6 md:mt-2 hidden sm:block lg:hidden text-lg 2xl:text-2xl">
            What will you learn?
          </h2>

          <div className="mt-4 hidden tracking-wider sm:grid lg:hidden sm:text-sm xl:text-base 2xl:text-lg text-gray-600 gap-4 grid-cols-2">
            <p>&#10003; Python and Math for ML</p>
            <p>&#10003; Machine Learning Concepts</p>
            <p>&#10003; ML Projects with Python Code</p>
            <p>&#10003; Guidance for ML Job Interview</p>
          </div>
          <LiveCourses />

          <h2 className="mt-8 tracking-wider text-center text-gray-900 text-lg xl:text-2xl">
            ML Course Modules
          </h2>
          <div className="sm:flex sm:justify-between">
            <a
              href="https://www.linkedin.com/in/ravishkumar2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="z-30 mt-6 text-center tracking-wider text-sm md:text-base 2xl:text-lg justify-end py-1 text-gray-600 hover:text-new-blue underline">
                <p> Mentor and Author: Ravish Kumar </p>
              </div>
            </a>

            <div className="flex justify-end">
              <SocialMedia />
            </div>
          </div>

          <div className="mt-4 text-center sm:w-72">
            <a
              href="https://www.enjoyalgorithms.com/crack-machine-leaning-interview/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="z-30 tracking-wider text-base lg:text-lg justify-center flex shadow px-4 py-2 rounded border border-new-purple text-white bg-white hover:bg-new-purple text-new-purple hover:text-white transition duration-150">
                <p> Try new look of this course </p>
              </div>
            </a>
          </div>

          <div className="mt-6 md:mt-8 xl:mt-10 grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 text-base sm:text-sm xl:text-base tracking-wider">
            {mlCourseModuleList.map(module => (
              <ModuleTable
                key={module.moduleNumber}
                moduleTitle={module.moduleName}
                items={module.chapters}
              />
            ))}
          </div>

          <div className="mt-6">
            <NewsletterStrip />
          </div>

          <div className="mt-6">
            <CoursesAndBlogs />
          </div>

          <Scroll showBelow={250} />
          <div className="mt-4">
            <Footer2 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MachineLearningCoursePage
