export const mlCourseModuleList = [
  {
    moduleNumber: 1,
    moduleName: "I. Python Fundamentals for ML",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Variable and Data Types",
        path: "/blog/basic-python-for-ml-data-science/",
      },
      {
        chapterNumber: 2,
        chapterName: "Tuples and Lists",
        path: "/blog/python-tuples-lists-for-ml-data-science/",
      },
      {
        chapterNumber: 3,
        chapterName: "Sets and Dictionaries",
        path: "/blog/sets-and-dictionaries-in-python-for-ml/",
      },
      {
        chapterNumber: 4,
        chapterName: "Conditions and Branching",
        path: "/blog/conditions-and-branching-in-python/",
      },
      {
        chapterNumber: 5,
        chapterName: "Introduction to Loop",
        path: "/blog/loops-in-python-for-ml-data-science/",
      },
      {
        chapterNumber: 6,
        chapterName: "Introduction to Function",
        path: "/blog/introduction-to-function-in-python/",
      },
      {
        chapterNumber: 7,
        chapterName: "Introduction to OOPS",
        path: "/blog/introduction-to-oops-in-python/",
      },
      {
        chapterNumber: 8,
        chapterName: "Introduction to Numpy",
        path: "/blog/introduction-to-numpy-in-python/",
      },
      {
        chapterNumber: 9,
        chapterName: "Introduction to Pandas",
        path: "/blog/introduction-to-pandas/",
      },
    ],
  },
  {
    moduleNumber: 2,
    moduleName: "II. Machine Learning Fundamentals",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "ML vs. Traditional Programming",
        path: "/blog/introduction-to-machine-learning/",
      },
      {
        chapterNumber: 2,
        chapterName: "AI vs Machine Learning",
        path: "/blog/difference-between-ai-ml/",
      },
      {
        chapterNumber: 3,
        chapterName: "Guide to Start Machine Learning",
        path: "/blog/how-to-start-machine-learning-journey-a-step-by-step-guidance/",
      },
      {
        chapterNumber: 4,
        chapterName: "How Exactly Machine Learns?",
        path: "/blog/cost-function-in-machine-learning/",
      },
      {
        chapterNumber: 5,
        chapterName: "Gradient Descent Algorithm",
        path: "/blog/parameter-learning-and-gradient-descent-in-ml/",
      },
      {
        chapterNumber: 6,
        chapterName: "Classification of ML models",
        path: "/blog/classification-of-machine-learning-models/",
      },
      {
        chapterNumber: 7,
        chapterName: "Supervised and Un-supervised",
        path: "/blogs/supervised-unsupervised-and-semisupervised-learning/",
      },
      {
        chapterNumber: 8,
        chapterName: "Classification and Regression",
        path: "/blogs/classification-and-regression-in-machine-learning/",
      },
       {
        chapterNumber: 9,
        chapterName: "Loss and Cost Function",
        path: "/blog/loss-and-cost-functions-in-machine-learning/",
      },
    ],
  },
  {
    moduleNumber: 3,
    moduleName: "III. Analyse and Play with Data",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Make System ML enabled",
        path: "/blog/make-system-ml-enabled/",
      },
      {
        chapterNumber: 2,
        chapterName: "Introduction to Matplotlib",
        path: "/blog/introduction-to-matplotlib/",
      },
      {
        chapterNumber: 3,
        chapterName: "Introduction to Seaborn",
        path: "/blog/introduction-to-seaborn-library-in-python/",
      },
      {
        chapterNumber: 4,
        chapterName: "Data Pre-processing in ML",
        path: "/blog/data-pre-processing-for-machine-learning/",
      },
      {
        chapterNumber: 5,
        chapterName: "Data Pre-processing hands-on",
        path: "/blog/structured-data-pre-processing-hands-on/",
      },
      {
        chapterNumber: 6,
        chapterName: "Univariate, Bivariate, Multivariate",
        path: "/blog/univariate-bivariate-multivariate-analysis/",
      },
      {
        chapterNumber: 7,
        chapterName: "Pre-processing of Text Data",
        path: "/blog/text-data-pre-processing-techniques-in-ml/",
      },
      {
        chapterNumber: 8,
        chapterName: "Word Vector Encoding",
        path: "/blog/word-vector-encoding-in-nlp/",
      },
      {
        chapterNumber: 9,
        chapterName: "Feature Selection Techniques",
        path: "/blog/feature-selection-techniques/",
      },
    ],
  },
  {
    moduleNumber: 4,
    moduleName: "IV. Performance Improvement Tools",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Need of Feature Scaling",
        path: "/blog/need-of-feature-scaling-in-machine-learning/",
      },
      {
        chapterNumber: 2,
        chapterName: "Regularization in ML",
        path: "/blog/regularization-in-machine-learning/",
      },
      {
        chapterNumber: 3,
        chapterName: "Bias-Variance Tradeoff",
        path: "/blog/bias-variance-tradeoff-in-machine-learning/",
      },
      {
        chapterNumber: 4,
        chapterName: "Evaluation of Regression Model",
        path: "/blog/evaluation-metrics-regression-models/",
      },
      {
        chapterNumber: 5,
        chapterName: "Evaluation of Classification Model",
        path: "/blog/evaluation-metrics-classification-models/",
      },
      {
        chapterNumber: 6,
        chapterName: "Guide to Build ML Projects",
        path: "/blog/pathway-for-machine-learning-projects/",
      },
      {
        chapterNumber: 7,
        chapterName: "Build ML Model from Scratch",
        path: "/blog/coding-ml-from-scratch/",
      },
      {
        chapterNumber: 8,
        chapterName: "Introduction to Scikit-Learn",
        path: "/blog/introduction-to-scikit-learn/",
      },
    ],
  },
  {
    moduleNumber: 5,
    moduleName: "V. Supervised Learning Part 1",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Linear Regression",
        path: "/blog/linear-regression-in-machine-learning/",
      },
      {
        chapterNumber: 2,
        chapterName: "Life Expectancy Prediction",
        path: "/blog/life-expectancy-prediction-using-linear-regression/",
      },
      {
        chapterNumber: 3,
        chapterName: "Logistic Regression",
        path: "/blog/logistic-regression-in-ml/",
      },
      {
        chapterNumber: 4,
        chapterName: "Optical Character Recognition",
        path: "/blog/optical-character-recognition-using-logistic-regression/",
      },
      {
        chapterNumber: 5,
        chapterName: "Support Vector Machines",
        path: "/blog/support-vector-machine-in-ml/",
      },
      {
        chapterNumber: 6,
        chapterName: "Cancer Classification",
        path: "/blog/cancer-classification-using-machine-learning/",
      },
      {
        chapterNumber: 7,
        chapterName: "K-Nearest Neighbors (KNN)",
        path: "/blog/k-nearest-neighbours-in-ml/",
      },
      {
        chapterNumber: 8,
        chapterName: "Email Spam Filtering",
        path: "/blog/email-spam-and-non-spam-filtering-using-machine-learning/",
      },
      {
        chapterNumber: 9,
        chapterName: "Wine Quality Prediction",
        path: "/blog/wine-quality-prediction/",
      },
    ],
  },
  {
    moduleNumber: 6,
    moduleName: "VI. Supervised Learning Part 2",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Naive Bayes Classifier",
        path: "/blog/naive-bayes-in-ml/",
      },
      {
        chapterNumber: 2,
        chapterName: "Sentiment Analysis",
        path: "/blog/sentiment-analysis-using-machine-learning/",
      },
      {
        chapterNumber: 3,
        chapterName: "Decision Tree Algorithm",
        path: "/blog/decision-tree-algorithm-in-ml/",
      },
      {
        chapterNumber: 4,
        chapterName: "Boosting and its Variants",
        path: "/blog/boosting-and-its-variants/",
      },
      {
        chapterNumber: 5,
        chapterName: "Random Forest Algorithm",
        path: "/blog/random-forest-in-ml/",
      },
      {
        chapterNumber: 6,
        chapterName: "How Uber uses ML?",
        path: "/blog/how-uber-use-machine-learning/",
      },
      {
        chapterNumber: 7,
        chapterName: "PUBG Cheaters Detection",
        path: "/blog/pubg-cheater-detection-system-using-ml/",
      },
      {
        chapterNumber: 8,
        chapterName: "Introduction to XG-Boost",
        path: "/blog/xg-boost-algorithm-in-ml/",
      },
      {
        chapterNumber: 9,
        chapterName: "Drug Discovery",
        path: "/blog/drug-discovery-using-ml/",
      },
    ],
  },
  {
    moduleNumber: 7,
    moduleName: "VII. Unsupervised Learning",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "K-Means Clustering",
        path: "/blog/k-means-clustering-algorithm/",
      },
      {
        chapterNumber: 2,
        chapterName: "Personality Prediction",
        path: "/blog/personality-prediction-using-ml/",
      },
      {
        chapterNumber: 3,
        chapterName: "Customer Segmentation",
        path: "/blog/customer-segmentation-using-hierarchical-clustering/",
      },
      {
        chapterNumber: 4,
        chapterName: "Principal Component Analysis",
        path: "/blog/principal-component-analysis-in-ml/",
      },
      {
        chapterNumber: 5,
        chapterName: "Image Compression using PCA",
        path: "/blog/image-compression-using-pca/",
      },
      {
        chapterNumber: 6,
        chapterName: "t-SNE Algorithm",
        path: "/blog/tsne-algorithm-in-ml/",
      },
    ],
  },
]
