import React from "react"
import { Link } from "gatsby"
export default class DSALiveCourses extends React.Component {
  render() {
    return (
      <div className="grid md:grid-cols-3 gap-6 sm:gap-8 mt-6 sm:mt-8 xl:mt-12 tracking-wider">
        <div className="rounded-2xl bg-white overflow-hidden shadow-md border p-2 md:p-0 lg:p-2 2xl:p-4">
          <div className="px-2 mt-4 lg:px-4 text-base md:text-sm lg:text-base xl:text-lg 2xl:text-xl text-gray-900 text-center">
            &#9734; 16-week live DSA course
          </div>
          <div className="px-4 sm:px-6 xl:px-8 py-3 justify-center flex mb-2">
            <Link
              to="/dsa-course-for-beginners-and-intermediate-learners/"
              className="z-30 w-72 sm:w-80 2xl:w-96 tracking-wider text-base md:text-sm lg:text-base xl:text-lg 2xl:text-xl justify-center flex shadow px-4 py-2 rounded  bg-new-green hover:bg-light-green text-white hover:text-gray-900 transition duration-150"
            >
              Explore details
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right ml-2"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </Link>
          </div>
        </div>

        <div className="rounded-2xl bg-white border overflow-hidden shadow-md p-2 md:p-0 lg:p-2 2xl:p-4">
          <div className="mt-4 px-2 lg:px-4 text-base md:text-sm lg:text-base xl:text-lg 2xl:text-xl text-gray-900 text-center">
            &#9734; 16-week live ML course
          </div>
          <div className="px-4 sm:px-6 xl:px-8 py-3 justify-center flex mb-2">
            <Link
              to="/live-machine-learning-course/"
              className="z-30 w-72 sm:w-80 2xl:w-96 tracking-wider text-base md:text-sm lg:text-base xl:text-lg 2xl:text-xl justify-center flex shadow px-4 py-2 rounded bg-new-purple hover:bg-light-purple text-white hover:text-gray-900 transition duration-150"
            >
              Explore details
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right ml-2"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </Link>
          </div>
        </div>

        <div className="rounded-2xl bg-white overflow-hidden border shadow-md p-2 md:p-0 lg:p-2 2xl:p-4">
          <div className="mt-4 px-2 lg:px-4 text-base md:text-sm lg:text-base xl:text-lg 2xl:text-xl text-gray-900 text-center">
            &#9734; 10-week live DSA course
          </div>
          <div className="px-4 sm:px-6 xl:px-8 py-3 justify-center flex mb-2">
            <Link
              to="/dsa-course-for-advanced-learners/"
              className="z-30 w-72 sm:w-80 2xl:w-96 tracking-wider text-base md:text-sm lg:text-base xl:text-lg 2xl:text-xl justify-center flex shadow px-4 py-2 rounded  bg-new-green hover:bg-light-green text-white hover:text-gray-900 transition duration-150"
            >
              Explore details
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right ml-2"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
